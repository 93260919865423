import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

export enum LoadingStates {
  UNKNOWN = "UNKNOWN",
  LOGIN = "LOGIN",
  LOGOUT = "LOGOUT",
  GLOBAL = "GLOBAL",
  CONFIRM = "CONFIRM",
  GET_PARTNERS = "GET_PARTNERS",
  GET_PARTNER = "GET_PARTNER",
  EDIT_PARTNER = "EDIT_PARTNER",
  GET_PARTNER_JURISTIC_PERSONS = "GET_PARTNER_JURISTIC_PERSONS",
  EDIT_PARTNER_JURISTIC_PERSONS_ACTIVATE = "EDIT_PARTNER_JURISTIC_PERSONS_ACTIVATE",
  EDIT_PARTNER_AUTH = "EDIT_PARTNER_AUTH",
  GET_GENERATE_AUTH = "GET_GENERATE_AUTH",
}

export interface LoadingInterface {
  id: string;
  type: string;
}

@Injectable({
  providedIn: "root",
})
export class LoadingService {
  constructor() {
  }

  StatesNames = LoadingStates;
  States = Object.keys(LoadingStates).map((x) => {
    return {state: x, value: false};
  });
  Loadings: LoadingInterface[] = [];
  IsLoadingChange$ = new Subject<boolean>();

  addLoading(requestType = LoadingStates.UNKNOWN): string {
    //console.log("\n\n")
    //console.log("STATES before adding = ", Object.assign({},this.States));

    const id = this.uuidv4();
    this.Loadings.push({
      id,
      type: requestType,
    });

    // console.warn( this.States, requestType);
    this.States.find((x) => x.state == requestType)!.value = true;

    //console.log("STATES afer adding = ", Object.assign({},this.States));
    //console.log("=== > Current Loadings { add } = ", Object.assign({},this.Loadings))
    this.IsLoadingChange$.next(true);
    //console.log("\n\n")
    return id;
  }

  removeLoading(id: string): LoadingInterface[] {
    //console.log("\n\n")
    //console.log("STATES before deleting = ", Object.assign({},this.States))
    const type = this.Loadings.find((x) => x.id == id)!.type;
    this.Loadings = this.Loadings.filter((f) => f.id != id);

    let countCurrentType = this.Loadings.filter((x) => x.type == type).length;
    this.States.find((x) => x.state == type)!.value =
      countCurrentType > 0 ? true : false;

    //console.log("STATES afer deleting = ", Object.assign({},this.States))
    //console.log("=== > Current Loadings { remove } = ", Object.assign({},this.Loadings))
    this.IsLoadingChange$.next(true);
    //console.log("\n\n")
    return this.Loadings;
  }

  isHasLoadingByType(requestType: LoadingStates) {
    return this.Loadings.findIndex((x) => x.type == requestType) > -1
      ? true
      : false;
  }

  uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
      var r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  getIdFromArrayStateByType(type: LoadingStates) {
    return this.States.findIndex((x) => x.state == type);
  }
}
